import React from "react";
import { inject, observer } from "mobx-react";

@inject("appStore")
@observer
class MyGradeQueryGroupComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
    };
  }

  render() {
    let { t, searchForm, appStore } = this.props;
    let form = { ...searchForm };
    let { resourceConfig } = appStore;
    let defaultResourceType =
      resourceConfig && resourceConfig.resourceTabList
        ? resourceConfig.resourceTabList[0]
        : "databases";
    if (!form.resourceType) form.resourceType = defaultResourceType;
    delete form["limit"];
    delete form["pageNo"];
    return (
      <>
        <div
          className="close"
          tabIndex="0"
          onClick={this.props.changeShowFilter.bind(this, false)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.props.changeShowFilter(false, e);
            }
          }}></div>
        <h2>{t("jumperrwd.common.myGradeSearch")}</h2>
        <div className="keyword ">
          <h3>{t("jumperrwd.common.searchFilterBykeyword")}</h3>
          <form>
            <div className="form_grp form_inline">
              <input
                name="pageType"
                id="pageType"
                type="hidden"
                value="myGrade"
              />
              <input
                name="resourceType"
                id="resourceType"
                type="hidden"
                value={form.resourceType}
              />
              <input
                name="custKey"
                id="custKey"
                type="text"
                title={t("jumperrwd.common.keywordFilter")}
                placeholder={t("jumperrwd.common.enterKeyword")}
                defaultValue={this.props.searchForm.custKey}
              />
              <input
                name=""
                type="submit"
                title={t("jumper.common.search")}
                value={t("jumper.common.search")}
              />
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default MyGradeQueryGroupComp;
