import React from "react";
import { autobind } from "react-decoration";
import client from "lib/ApolloClient";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import navigate from "lib/navigate";
import qs from "query-string";

const getLibHistoryQueryGroup = gql`
  query getLibHistoryQueryGroup {
    getLibHistoryQueryGroup {
      queryGroupList {
        cate_id
      }
    }
  }
`;

@inject("appStore", "readerStore")
@observer
class LibHistoryQueryGroupComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
      refetch: null,
    };
  }

  @autobind
  changeCateId(cate_id) {
    let { location } = this.props;
    let params = {
      pageType: "libHistory",
      cate_id: cate_id,
    };
    navigate([location.pathname, qs.stringify(params)].join("?"));
    if (this.props.showFilter) {
      this.props.changeShowFilter(false);
    }
  }

  render() {
    let { t, searchForm } = this.props;
    let theCateId = searchForm.cate_id || "Notice";
    return (
      <>
        <div
          className="close"
          tabIndex="0"
          onClick={this.props.changeShowFilter.bind(this, false)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.props.changeShowFilter(false, e);
            }
          }}></div>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query query={getLibHistoryQueryGroup} fetchPolicy="network-only">
            {({ loading, data, refetch, error }) => {
              if (error) return "";
              if (loading) return "Loading...";
              if (this.state.refetch === null) {
                this.setState({ refetch: refetch }, () => {
                  if (this.props.getRefetch) {
                    this.props.getRefetch(refetch);
                  }
                });
              }

              if (
                data.getLibHistoryQueryGroup !== null &&
                data.getLibHistoryQueryGroup !== undefined
              ) {
                let { queryGroupList } = data.getLibHistoryQueryGroup;
                return (
                  <>
                    <h2>{t("jumperrwd.common.libHistory")}</h2>
                    <div className="sort_list">
                      <ul>
                        <li>
                          <div className="list_content">
                            <ul>
                              {queryGroupList &&
                                queryGroupList.map((item, i) => {
                                  let { cate_id } = item;
                                  return (
                                    <li
                                      className={
                                        theCateId === cate_id ? "active" : ""
                                      }>
                                      <a
                                        tabIndex="0"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.changeCateId(cate_id);
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            this.changeCateId(cate_id);
                                          }
                                        }}>
                                        {t(
                                          "jumperrwd.jpHylibApicate.name." +
                                            cate_id
                                        )}
                                      </a>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </>
                );
              }
            }}
          </Query>
        </ApolloProvider>
      </>
    );
  }
}

export default LibHistoryQueryGroupComp;
