const cutIntro = (str, size) => {
  if (str.length < size) {
    return str + "...";
  } else {
    let tmp = str.substring(0, size);
    let checkstr = str.substring(size);
    let start = -1,
      end = -1;
    for (let i = 0; i < checkstr.length; i++) {
      if (
        start < 0 &&
        checkstr.charAt(i) === "<" &&
        checkstr.charAt(i + 1) === "/"
      ) {
        start = i;
      }
      if (start >= 0 && checkstr.charAt(i) === ">") {
        end = i + 1;
        break;
      }
    }
    if (start > size) {
      tmp += checkstr.substring(0, size);
    } else {
      if (start >= 0 && end > 0) {
        tmp += checkstr.substring(0, end);
      }
    }

    tmp += "...";

    let out_str = tmp;
    tmp = null;
    return out_str;
  }
};

const htmlRemoveTag = (inputString) => {
  let htmlStr = inputString || ""; // 含html標籤的字串
  if (htmlStr) {
    let textStr = "";
    let script_regexp = new RegExp(
      "<[\\s]*?script[^>]*?>[\\s\\S]*?<[\\s]*?\\/[\\s]*?script[\\s]*?>",
      "gm"
    );
    let style_regexp = new RegExp(
      "<[\\s]*?style[^>]*?>[\\s\\S]*?<[\\s]*?\\/[\\s]*?style[\\s]*?>",
      "gm"
    );
    let html_regexp = new RegExp("<[^>]+>", "gm");

    htmlStr = htmlStr.replace(script_regexp, ""); // 過濾script標籤
    htmlStr = htmlStr.replace(style_regexp, ""); // 過濾style標籤
    htmlStr = htmlStr.replace(html_regexp, ""); // 過濾html標籤
    textStr = htmlStr;
    return textStr; // 返回文本字串
  } else {
    return "";
  }
};

const getFileTypePhoto = (attachPath) => {
  let fileTypePhoto = "";
  let tmp = attachPath.split(".");
  if (tmp !== undefined) {
    if (tmp[tmp.length - 1] === "pdf") {
      fileTypePhoto = "/file/images/pdf.gif";
    } else if (
      tmp[tmp.length - 1] === "xls" ||
      tmp[tmp.length - 1] === "xlsx"
    ) {
      fileTypePhoto = "/file/images/excel.gif";
    } else if (tmp[tmp.length - 1] === "exe") {
      fileTypePhoto = "/file/images/exe.gif";
    } else if (tmp[tmp.length - 1] === "rar") {
      fileTypePhoto = "/file/images/rar.gif";
    } else if (
      tmp[tmp.length - 1] === "ppt" ||
      tmp[tmp.length - 1] === "pptx"
    ) {
      fileTypePhoto = "/file/images/ppt.gif";
    } else if (
      tmp[tmp.length - 1] === "doc" ||
      tmp[tmp.length - 1] === "docx"
    ) {
      fileTypePhoto = "/file/images/word.gif";
    } else {
      fileTypePhoto = "/file/images/data3.jpg";
    }
  }
  return fileTypePhoto;
};

export default {
  cutIntro,
  htmlRemoveTag,
  getFileTypePhoto,
};
