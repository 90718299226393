import React from "react";
//import moment from "moment";
import LightBox from "components/common/LightBox";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { autobind } from "react-decoration";
import { arrangeData } from "components/list/action/action-util";
import Link from "lib/Link";

const getSdiDetail = gql`
  query getSdiDetail($sdiType: String, $schname: String) {
    getSdiDetail(sdiType: $sdiType, schname: $schname) {
      dblist {
        dbid
        ebname
        id
        schdbid
      }
    }
  }
`;

const getSdiSearchResult = gql`
  query getSdiSearchResult($form: SdiSearchResultForm) {
    getSdiSearchResult(Input: $form) {
      data {
        values {
          key
          value
        }
      }
    }
  }
`;

@LightBox
class ResultViewLightBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lookDb: "",
    };
  }

  @autobind
  handleChangeView(e) {
    this.setState({
      lookDb: e.target.value,
    });
  }

  render() {
    let { sdiType, schname, sn, t } = this.props;

    if (sdiType === "eds") {
      schname = sn;
    }
    return (
      <>
        {sdiType === "hyint" && (
          <div>
            {t("jumperrwd.sdi.selectViewResource")}：
            <select
              title={t("jumperrwd.sdi.selectViewResource")}
              onChange={this.handleChangeView}>
              <Query
                query={getSdiDetail}
                variables={{
                  sdiType: sdiType,
                  schname: schname,
                }}>
                {({ loading, data, refetch, error }) => {
                  if (error) return "";
                  if (loading) return "Loading...";

                  let options = [];
                  options.push(
                    <option value="">
                      {t("jumperrwd.common.pleaseChoose")}
                    </option>
                  );
                  if (data !== null && data) {
                    let list = data.getSdiDetail.dblist;
                    list.map((item) => {
                      options.push(
                        <option value={item.schdbid}>{item.ebname}</option>
                      );
                      return "";
                    });
                  }
                  return options;
                }}
              </Query>
            </select>
          </div>
        )}
        <div className="scroll_content">
          <div className="table_list">
            <table className="table_sprite">
              <thead>
                <tr>
                  <th width="10%">{t("jumperrwd.sdi.seq")}</th>
                  <th>{t("jumperrwd.common.resultName")}</th>
                </tr>
              </thead>
              <tbody>
                <Query
                  displayName="graphql"
                  query={getSdiSearchResult}
                  variables={{
                    form: {
                      sdiType: sdiType,
                      schname: schname,
                      lookDb: this.state.lookDb,
                    },
                  }}>
                  {({ loading, data, error }) => {
                    if (error) return "";
                    if (loading) return "Loading...";

                    if (
                      data.getSdiSearchResult.data !== null &&
                      data.getSdiSearchResult.data !== undefined
                    ) {
                      let list = data.getSdiSearchResult.data;
                      let dd = [];
                      let seq = 1;
                      list.map((items) => {
                        // "id","title","plink","contributors","source","an"
                        const arrangedData = arrangeData(items.values);
                        if (sdiType === "eds") {
                          let { Title, URL } = arrangedData;

                          dd.push(
                            <>
                              {/*
                              <div>
                                {seq}.<a href={URL}>{Title}</a>
                              </div>
                              <div>作者：{Contributors}</div>
                              <div>來源：{Source}</div>
                              */}
                              <tr>
                                <td data-title={t("jumperrwd.sdi.seq")}>
                                  {seq}
                                </td>
                                <td data-title={t("jumperrwd.sdi.strategy")}>
                                  <Link href={URL}>{Title}</Link>
                                </td>
                              </tr>
                            </>
                          );
                        } else if (sdiType === "hyint") {
                          let { Title, URLLink } = arrangedData;

                          let content = [];
                          let tag_name = {};
                          tag_name["Author"] =
                            t("jumperrwd.common.author") + "：";
                          tag_name["Publisher"] =
                            t("jumperrwd.common.publisher") + "：";
                          tag_name["Date"] = t("jumperrwd.common.date") + "：";
                          tag_name["Index"] =
                            t("jumperrwd.common.index") + "：";
                          tag_name["Isbn"] = t("jumperrwd.common.isbn") + "：";
                          tag_name["Issn"] = t("jumperrwd.common.issn") + "：";
                          tag_name["Source"] = t("jumperrwd.sdi.source") + "：";
                          tag_name["Class1"] =
                            t("jumperrwd.common.class1") + "：";
                          tag_name["Journal"] =
                            t("jumperrwd.sdi.journal") + "：";
                          tag_name["Volume"] =
                            t("jumperrwd.common.volume") + "：";
                          tag_name["Issue"] =
                            t("jumperrwd.common.issue") + "：";
                          tag_name["Page"] =
                            t("jumperrwd.common.pageNo") + "：";
                          tag_name["Abstract"] =
                            t("jumperrwd.sdi.abstract") + "：";
                          tag_name["Dbname"] =
                            t("jumperrwd.sdi.dbResource") + "：";

                          /* "startpage":"開始頁數：",
                          "endpage":"結束頁數：",
                          "photo":"photo","command":"command","grade":"grade","dbname":"來源：",
                        "eissn":"E-issn",
                        "doi":"doi"*/

                          Object.keys(tag_name).forEach((key) => {
                            if (
                              arrangedData[key] !== undefined &&
                              arrangedData[key].trim() !== ""
                            ) {
                              content.push(
                                <div>
                                  {tag_name[key]}
                                  {arrangedData[key]}
                                </div>
                              );
                            }
                          });
                          dd.push(
                            <>
                              {/*<div>
                                {seq}.
                                <a href={URLLink}>
                                  {Title === "" ? "無檔案名稱" : Title}
                                </a>
                              </div>
                              <div>
                                <a href={URLFulltext}>檔案全文</a>
                              </div>
                              {URLHtml !== undefined && URLHtml !== "" && (
                                <div>
                                  <a href={URLHtml}>網頁全文</a>
                                </div>
                              )}
                              {content}*/}
                              <tr>
                                <td data-title={t("jumperrwd.sdi.seq")}>
                                  {seq}
                                </td>
                                <td data-title={t("jumperrwd.sdi.strategy")}>
                                  <Link href={URLLink}>
                                    {Title === ""
                                      ? t("jumperrwd.common.noFileName")
                                      : Title}
                                  </Link>
                                </td>
                              </tr>
                            </>
                          );
                        }

                        seq++;
                        return "";
                      });
                      return dd;
                    }
                    return (
                      <tr>
                        <td colSpan="2">{t("jumperrwd.sdi.noExecuteQuery")}</td>
                      </tr>
                    );
                  }}
                </Query>
              </tbody>
            </table>
          </div>
        </div>
        <div className="btn_grp">
          <button
            className="btn btn-submit"
            tabIndex="0"
            onClick={this.props.close}>
            {t("jumperrwd.common.close")}
          </button>
        </div>
      </>
    );
  }
}

export default ResultViewLightBox;
