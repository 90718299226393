import React from "react";
import qs from "query-string";
import PropTypes from "prop-types";
import moment from "moment";

import { arrangeData } from "components/list/action/action-util";
import View from "components/list/view/View";
import Link from "lib/Link";

class SearchHistoryDisplayComp extends View {
  constructor(props) {
    super(props);
    this.state = {};
  }

  searchCovertStr(str, stag) {
    console.log("tmp_field", str);
    let out_str = "";
    let getERMFieldStr = {
      databases: "jumperrwd.eb_basic_extension.name.",
      journal: "jumperrwd.eb_journal_extension.journalName.",
      ejournal: "jumperrwd.eb_journal_extension.journalName.",
      paper_journal: "jumperrwd.eb_journal_extension.journalName.",
      ebook: "jumperrwd.eb_journal_extension.ebookName.",
    };

    if (str.indexOf(";") >= 0) {
      //進階查詢
      let tmp = str.split(";");
      if (tmp.length === 3) {
        let tmp_key = tmp[0].split("|");
        let tmp_field = tmp[1].split("|");
        let tmp_logic = tmp[2].split("|");
        if (tmp_field[0] === "all") {
          out_str =
            decodeURIComponent(tmp_key[0]) +
            "(" +
            this.props.t("jumper.common.all") +
            ") ";
        } else {
          out_str =
            decodeURIComponent(tmp_key[0]) +
            "(" +
            this.props.t(getERMFieldStr[stag] + tmp_field[0]) +
            ") ";
        }
        if (tmp_key[1] !== "NULL") {
          if (tmp_field[1] === "all") {
            out_str +=
              tmp_logic[0] +
              " " +
              decodeURIComponent(tmp_key[1]) +
              "(" +
              this.props.t("jumper.common.all") +
              ") ";
          } else {
            out_str +=
              tmp_logic[0] +
              " " +
              decodeURIComponent(tmp_key[1]) +
              "(" +
              this.props.t(getERMFieldStr[stag] + tmp_field[1]) +
              ") ";
          }
        }
        if (tmp_key[2] !== "NULL") {
          if (tmp_field[2] === "all") {
            out_str +=
              tmp_logic[1] +
              " " +
              decodeURIComponent(tmp_key[2]) +
              "(" +
              this.props.t("jumper.common.all") +
              ") ";
          } else {
            out_str +=
              tmp_logic[1] +
              " " +
              decodeURIComponent(tmp_key[2]) +
              "(" +
              this.props.t(getERMFieldStr[stag] + tmp_field[2]) +
              ") ";
          }
        }
      }
    } else {
      out_str = str; //簡易查詢
    }
    return out_str;
  }

  render() {
    let { data, rowStyle, handleClick, t } = this.props;

    const arrangedData = arrangeData(data);

    let { title, cretime, type, img, link = "", cnt, stag } = arrangedData;
    if (title === undefined || title === "") {
      title = "(" + t("jumperrwd.common.noTitle") + ")";
    } else {
      title = this.searchCovertStr(title, stag);
    }

    if (cnt === undefined) {
      cnt = 0;
    }

    let openResource = false;
    let openResourceType = "databases";
    let resourceType = "";
    let id = "";
    if (link.includes("/resourceDetail")) {
      openResource = true;
      let { query } = qs.parseUrl(link);
      resourceType = query.resourceType;
      id = query.id;
      if (resourceType !== "databases") {
        openResourceType = "journal";
      }
    }

    return (
      <div className={`list_block ${rowStyle}`}>
        <div className="sort_icon">
          <div className="pic">
            <img src={`/file/images/icon/` + img} alt="搜尋標示" />
          </div>
          <div className="sort_name">
            <span
              dangerouslySetInnerHTML={{
                __html: this.props.t(`jumper.logtype.` + type),
              }}
            />
          </div>
        </div>
        <div className="list_data">
          <time>
            {moment(cretime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")}
          </time>
          {openResource ? (
            <a
              onClick={(e) => {
                e.preventDefault();
                handleClick(openResourceType, {
                  id: id,
                  resourceType: resourceType,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleClick(openResourceType, {
                    id: id,
                    resourceType: resourceType,
                  });
                }
              }}
              className="record_link">
              {title}
              {
                // ("0".equals(var[3].toString()) || "3".equals(var[3].toString()) || "4".equals(var[3].toString()))
                (type === "0" || type === "3" || type === "4") &&
                  `(` + cnt + `)`
              }
            </a>
          ) : (
            <Link href={link} className="record_link">
              {title}
              {
                // ("0".equals(var[3].toString()) || "3".equals(var[3].toString()) || "4".equals(var[3].toString()))
                (type === "0" || type === "3" || type === "4") &&
                  `(` + cnt + `)`
              }
            </Link>
          )}
        </div>
      </div>
    );
  }
}

SearchHistoryDisplayComp.propTypes = {
  /**
   * The layout define display config is used to define layout.
   * The datasoure is from LayoutReturnResult.display.
   */
  displayConfig: PropTypes.array.isRequired,

  /** td content */
  data: PropTypes.array,

  /** layout define's func, record link or button behavior. */
  functionConfigList: PropTypes.array,

  /** layout define's func_right, record link or button behavior. */
  functionRightConfigList: PropTypes.array,

  /** layout define's left_right, record link or button behavior. */
  functionLeftConfigList: PropTypes.array,

  /** layout define's detail */
  detailList: PropTypes.object,

  /** button events */
  buttonEvent: PropTypes.func,

  /**
   * batch config
   */
  batchConfig: PropTypes.array,

  /**
   * serialNo
   */
  serialNo: PropTypes.number,

  /**
   * checked the row or not;
   */
  checked: PropTypes.bool,

  /**
   * row style className
   */
  rowStyle: PropTypes.string,
};

SearchHistoryDisplayComp.defaultProps = {
  buttonEvent: () => {},
  functionConfigList: [],
  functionRightConfigList: [],
  functionLeftConfigList: [],
  detailList: [],
  batchConfig: [],
  serialNo: -1,
  checked: false,
  rowStyle: "",
};

export default SearchHistoryDisplayComp;
