import React from "react";
import ResourceDetailLightBox from "components/resource/ResourceDetailLightBox";
import JournalResourceDetailComp from "components/resource/JournalResourceDetailComp";
import { autobind } from "react-decoration";

@ResourceDetailLightBox
class JournalResourceDetailLightBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFixed: "none",
      scrolldown_fix: "",
    };
  }

  componentDidMount() {
    this.scrollContent.addEventListener("scroll", this.scrollTop);
  }

  componentWillUnmount() {
    this.scrollContent.removeEventListener("scroll", this.scrollTop);
  }

  @autobind
  scrollTop() {
    if (this.scrollContent.scrollTop >= 50) {
      this.setState({
        scrolldown_fix: "scrolldown_fix",
        displayFixed: "block",
      });
    } else {
      this.setState({ scrolldown_fix: "", displayFixed: "none" });
    }
  }

  render() {
    let { t } = this.props;
    return (
      <>
        <h2 className="title">{t("jumperrwd.common.bookDetail")}</h2>
        <div
          className="scroll_content"
          ref={(c) => {
            this.scrollContent = c;
          }}>
          <JournalResourceDetailComp
            {...this.props}
            {...this.state}
            isLightBox={true}
          />
        </div>
      </>
    );
  }
}

export default JournalResourceDetailLightBox;
